import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Navigate, useRoutes } from 'react-router-dom';
import { AuthContext } from './providers/AuthProvider';

// layouts
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
//
import BlogPage from './pages/BlogPage';
import RecordingsPage from './pages/RecordingsPage';
import LoginPage from './pages/LoginPage';
import SignupPage from './pages/SignupPage';
import LogoutPage from './pages/LogoutPage';
import EmailVerification from './sections/auth/EmailVerification';
import AuthCallback from './sections/auth/AuthCallback';
import Page404 from './pages/Page404';
import NoConnection from './pages/NoConnection';
import ProductsPage from './pages/ProductsPage';
import DashboardAppPage from './pages/DashboardAppPage';
import TranscriptsPage from './pages/TranscriptsPage';
import TemplatesPage from './pages/TemplatesPage';
import ReportsPage from './pages/ReportsPage';
import SessionsPage from './pages/SessionsPage';
import SessionDetailPage from './pages/SessionDetailPage';
import RecorderPage from './pages/RecorderPage';
// import CheckoutPage from './pages/CheckoutPage';
// import CheckoutReturnPage from './pages/CheckoutReturnPage';
import ProfilePage from './pages/ProfilePage';
import SubscribePage from './pages/SubscribePage';
import ReferralPage from './pages/ReferralPage';
import SubscriptionReturnPage from './pages/SubscriptionReturnPage';
import HowToRecord from './pages/HowToRecord';
import ConsentPage from './pages/ConsentPage';
import WorkspacePage from './pages/WorkspacePage';
import AcceptInvitePage from './pages/AcceptInvitePage';

const ProtectedRoute = ({ children, canBuyCredits = false }) => (canBuyCredits ? children : <Navigate to="/home" />);

ProtectedRoute.propTypes = {
  children: PropTypes.node.isRequired,
  canBuyCredits: PropTypes.bool,
};

export default function Router() {
  const { currentUser } = useContext(AuthContext);
  const signedIn = currentUser && currentUser.DBuser;

  const routes = useRoutes([
    {
      path: '/',
      element: signedIn ? <Navigate to="/home" /> : <Navigate to="/login" />,
    },
    {
      path: '/home',
      element: signedIn ? <DashboardLayout /> : <Navigate to="/login" />,
      children: [
        { element: <Navigate to="/home/sessions" />, index: true },
        { path: 'app', element: <DashboardAppPage /> },
        { path: 'sessions', element: <SessionsPage /> },
        { path: 'recorder', element: <RecorderPage /> },
        { path: 'sessions/:sessionId', element: <SessionDetailPage /> },
        { path: 'recordings', element: <RecordingsPage /> },
        { path: 'transcripts', element: <TranscriptsPage /> },
        { path: 'reports', element: <ReportsPage /> },
        { path: 'referral', element: <ReferralPage /> },
        { path: 'how-to-record', element: <HowToRecord /> },
        {
          path: 'consent',
          children: [
            { path: '', element: <ConsentPage /> }, // Change this line
            { path: ':consentId', element: <ConsentPage /> },
          ],
        },
        // {
        //   path: 'checkout',
        //   element: (
        //     <ProtectedRoute canBuyCredits={currentUser && currentUser.DBuser?.canBuyCredits}>
        //       <CheckoutPage />
        //     </ProtectedRoute>
        //   ),
        // },
        // {
        //   path: 'checkout-return',
        //   element: (
        //     <ProtectedRoute canBuyCredits={currentUser && currentUser.DBuser?.canBuyCredits}>
        //       <CheckoutReturnPage />
        //     </ProtectedRoute>
        //   ),
        // },
        {
          path: 'subscribe',
          element: (
            <ProtectedRoute canBuyCredits={currentUser && currentUser.DBuser?.canBuyCredits}>
              <SubscribePage />
            </ProtectedRoute>
          ),
        },
        { path: 'subscription-return', element: <SubscriptionReturnPage /> },
        { path: 'profile', element: <ProfilePage /> },
        { path: 'templates', element: <TemplatesPage /> },
        { path: 'products', element: <ProductsPage /> },
        { path: 'blog', element: <BlogPage /> },
        { path: 'workspace', element: <WorkspacePage /> },
      ],
    },
    {
      path: 'login',
      element: signedIn ? <Navigate to="/home" /> : <LoginPage />,
    },
    {
      path: 'signup/:referralCode',
      element: signedIn ? <Navigate to="/home" /> : <SignupPage />,
    },
    {
      path: 'signup',
      element: signedIn ? <Navigate to="/home" /> : <SignupPage />,
    },
    {
      path: 'logout',
      element: signedIn ? <LogoutPage /> : <Navigate to="/home" />, // Add the logout route
    },
    {
      path: 'auth/callback',
      element: <AuthCallback />,
    },
    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/home/sessions" />, index: true },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
        { path: 'NoConnection', element: <NoConnection /> },
        {
          path: 'consent/sign/:consentUid',
          element: <ConsentPage />,
        },
      ],
    },
    {
      path: 'verify-email',
      element: <EmailVerification />,
    },
    {
      path: '/accept-invite/:workspaceId/:userId?',
      element: <AcceptInvitePage />,
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
