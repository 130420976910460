import { useState, useEffect, useContext } from 'react';
import { S3Client } from '@aws-sdk/client-s3';
import { Upload } from '@aws-sdk/lib-storage';
import { FlagIcon } from 'react-flag-kit';
import { logEvent } from 'firebase/analytics';
import { useTheme } from '@mui/material/styles';
import {
  TextField,
  Grid,
  Alert,
  Box,
  Chip,
  FormControl,
  FormControlLabel,
  Select,
  InputLabel,
  FormHelperText,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  MenuItem,
  Checkbox,
  FormLabel,
  RadioGroup,
  Radio,
  Modal,
} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PropTypes from 'prop-types';
import { useForm, Controller } from 'react-hook-form';
import { analytics } from '../../../firebase/firebase';
import { AuthContext } from '../../../providers/AuthProvider';
import { apiRequest } from '../../../api/api';
import WundaButton from '../../../components/wundamental/WundaButton';
import WundaProgress from '../../../components/wundamental/WundaProgress';
import Iconify from '../../../components/iconify';
import languages from '../../../config/languages.json';

const SessionUploadForm = ({ sessionFile, onCancel, onSuccess }) => {
  const { updateUserData, userData, currentUser } = useContext(AuthContext);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
    setValue,
    watch,
  } = useForm({
    defaultValues: {
      name: sessionFile.file.name.split('.').slice(0, -1).join('.'),
      audioFile: null,
      tags: [],
      language: userData.preferences?.defaultLanguage || 'en',
      theme: userData.defaultTheme ? userData.defaultTheme._id : null,
    },
  });

  const selectedLanguage = watch('language');
  const [makeDefaultLanguage, setMakeDefaultLanguage] = useState(userData.preferences?.defaultLanguage === 'en');
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [tags, setTags] = useState([]);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [cancelUpload, setCancelUpload] = useState(null);

  // Configure AWS SDK
  const s3Client = new S3Client({
    region: process.env.REACT_APP_AWS_REGION,
    credentials: {
      accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
      secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
    },
  });

  useEffect(() => {
    if (selectedLanguage === (userData.preferences?.defaultLanguage || 'en')) {
      setMakeDefaultLanguage(true);
    } else {
      setMakeDefaultLanguage(false);
    }
  }, [selectedLanguage, userData.preferences?.defaultLanguage]);

  const handleUploadSubmit = async (data) => {
    const { name, language, flourisherName, tags, theme } = data;

    // Check if the file size exceeds 700MB
    if (sessionFile.file.size > 700 * 1024 * 1024) {
      setErrorMessage('File size exceeds 700MB. Please upload a smaller file.');
      return;
    }

    // Extract extension from original file name
    const userId = userData._id || currentUser?.DBuser?._id;
    if (!userId) {
      setErrorMessage('User ID not found. Please try again later.');
      return;
    }

    const extension = sessionFile.file.name.split('.').pop();
    // Create unique file name with extension
    const uniqueFileName = `${Date.now()}-${crypto.randomUUID()}.${extension}`;

    const s3Key = `uploads/${userId}/${uniqueFileName}`;

    // Upload file to S3
    const uploadParams = {
      Bucket: process.env.REACT_APP_AWS_BUCKET_NAME, // replace with your bucket name
      Key: s3Key,
      Body: sessionFile.file,
      // ACL: 'public-read',
    };
    let upload;

    try {
      setLoading(true);
      try {
        const queueSize = 10;
        let partSize = sessionFile.file.size / 10; // 1/10th of the file size in bytes
        partSize = Math.max(partSize, 5 * 1024 * 1024); // Ensure partSize is at least 5MB (in bytes)

        upload = new Upload({
          client: s3Client,
          params: uploadParams,
          queueSize: partSize > 5 * 1024 * 1024 ? queueSize : undefined,
          partSize: partSize > 5 * 1024 * 1024 ? partSize : undefined,
        });

        upload.on('httpUploadProgress', (progress) => {
          const percentage = Math.round((progress.loaded / progress.total) * 100);
          setUploadProgress(percentage);
        });

        setCancelUpload(() => () => upload.abort());

        await upload.done();
        console.log('File uploaded successfully');
      } catch (error) {
        if (error.name === 'AbortError') {
          console.log('Upload aborted');
        } else {
          console.error('Upload failed:', error);
        }
        return;
      }

      // Create data object
      const data = {
        name,
        language,
        fileName: sessionFile.file.name,
        fileType: sessionFile.file.type,
        s3Key,
        tags: JSON.stringify(tags),
        defaultLanguage: makeDefaultLanguage,
      };

      if (theme) {
        data.themeID = theme;
      }

      if (flourisherName) {
        data.flourisherName = flourisherName;
      }

      if (sessionFile.metadata?.duration != null) {
        data.duration = sessionFile.metadata.duration;
      }

      // Send POST request using apiRequest
      const response = await apiRequest('POST', '/session/upload', {
        data,
      });
      if (!response) {
        throw new Error('No response from server');
      }

      if (makeDefaultLanguage) {
        await updateUserData({ DBuser: { preferences: { defaultLanguage: language } } });
      }

      // Show success message
      setSuccessMessage('Session file uploaded successfully');

      // Send analytics event
      logEvent(analytics, 'new_session');

      // Reset form fields
      reset();

      // Call the onSuccess callback with the success message
      if (onSuccess) {
        onSuccess(successMessage);
      }

      // Call the onCancel callback with the success message
      if (onCancel) {
        onCancel();
      }
    } catch (error) {
      // Handle errors
      console.error('Upload failed:', error.message);

      // Show error message
      setErrorMessage(
        `Error uploading audio file: ${error.response?.data?.errorDetail || error.response?.data?.message || error.message}`
      );
    } finally {
      setLoading(false);
      setCancelUpload(null);
    }
  };

  const handleCancel = () => {
    if (cancelUpload) {
      cancelUpload();
    }
  };

  return (
    <form onSubmit={handleSubmit(handleUploadSubmit)}>
      <Grid
        container
        spacing={2}
        sx={{
          backgroundColor: theme.palette.background.paper,
          padding: '20px',
          borderRadius: '10px',
        }}
      >
        <Grid item xs={12} sx={{ width: '50%' }}>
          <Controller
            control={control}
            name="name"
            rules={{ required: 'Please enter name for your session' }}
            render={({ field }) => (
              <TextField
                {...field}
                label="Session Name *"
                fullWidth
                error={!!errors.name}
                helperText={errors.name?.message}
                className="fs-mask"
              />
            )}
          />
        </Grid>
        <Grid item xs={isMobile ? 12 : 6} sx={{ p: 0, borderRadius: 2 }}>
          <Controller
            control={control}
            name="language"
            defaultValue={userData.preferences?.defaultLanguage || 'en'}
            rules={{ required: 'Please select a language that was spoken in the session' }}
            render={({ field }) => (
              <FormControl variant="outlined" fullWidth>
                <InputLabel id="language-label">Language</InputLabel>
                <Select
                  labelId="language-label"
                  id="language"
                  {...field}
                  onChange={(e) => {
                    field.onChange(e);
                  }}
                  label="Language"
                >
                  {languages.map((language) => (
                    <MenuItem key={language.value} value={language.value}>
                      <Box display="flex" alignItems="center">
                        <FlagIcon code={language.flag} size={24} />
                        <Typography variant="body1" style={{ marginLeft: 8 }}>
                          {language.label}
                        </Typography>
                      </Box>
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>What language was spoken in the session?</FormHelperText>
                {errors.language && <FormHelperText>{errors.language.message}</FormHelperText>}
              </FormControl>
            )}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={makeDefaultLanguage}
                onChange={(e) => setMakeDefaultLanguage(e.target.checked)}
                name="makeDefault"
                color="primary"
              />
            }
            label={<Typography variant="body2">Make this as my default language</Typography>}
          />
        </Grid>
        <Grid item xs={12} sx={{ width: '50%' }}>
          <Controller
            control={control}
            name="flourisherName"
            render={({ field }) => (
              <TextField
                {...field}
                label="Client Name"
                placeholder="Optional. This name would be shown in the insight reports."
                fullWidth
                error={!!errors.flourisherName}
                helperText={errors.flourisherName?.message}
                className="fs-mask"
              />
            )}
          />
        </Grid>
        {userData.allowedThemes && userData.allowedThemes.length > 1 && (
          <Grid item xs={12}>
            <Controller
              control={control}
              name="theme"
              defaultValue={userData.defaultTheme ? userData.defaultTheme._id : null}
              rules={{ required: 'Please select your use case' }}
              render={({ field }) => (
                <FormControl component="fieldset">
                  <FormLabel component="legend">Use case:</FormLabel>
                  <RadioGroup {...field}>
                    {userData.allowedThemes.map((theme) => (
                      <FormControlLabel key={theme._id} value={theme._id} control={<Radio />} label={theme.name} />
                    ))}
                  </RadioGroup>
                  {errors && errors.themeId && <FormHelperText>{errors.themeId?.message}</FormHelperText>}
                </FormControl>
              )}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <Accordion
            sx={{
              backgroundColor: theme.palette.grey[200],
              border: '1px solid',
              borderColor: theme.palette.grey[400],
              marginTop: theme.spacing(2),
              marginBottom: theme.spacing(2),
            }}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
              <Typography>Advanced...</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <TextField
                label="Tags"
                variant="outlined"
                fullWidth
                placeholder="Enter a tag and press Enter or any non-alphanumeric key"
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    e.preventDefault();
                  }
                }}
                onKeyUp={(event) => {
                  // Use regex to remove any non-alphanumeric characters
                  const sanitizedValue = event.target.value.replace(/[^a-zA-Z0-9]/g, '').trim();

                  // This regex checks if the key pressed is non-alphanumeric or if it's the Enter key
                  const isDelimiter = /[^a-zA-Z0-9]/.test(event.key) || event.key === 'Enter';

                  if (isDelimiter && sanitizedValue !== '') {
                    // Only add the tag if it doesn't already exist in the array
                    if (!tags.includes(sanitizedValue)) {
                      const newTags = [...tags, sanitizedValue];
                      setTags(newTags);
                      setValue('tags', newTags); // update the form value too
                    }
                    // Clear the text field regardless of whether it's a duplicate or not
                    event.target.value = '';
                  }
                }}
              />
              <FormHelperText>
                Enter tags related to the session. Tags help you categorize your sessions. Press Enter or any
                non-alphanumeric key to add a tag.
              </FormHelperText>
              <Box mt={2}>
                {tags.map((tag, index) => (
                  <Chip
                    key={index}
                    label={tag}
                    onDelete={() => {
                      const newTags = tags.filter((t) => t !== tag);
                      setTags(newTags);
                      setValue('tags', newTags); // also update the form value
                    }}
                    style={{ marginRight: '5px', marginBottom: '5px' }}
                  />
                ))}
              </Box>
              <Controller name="tags" control={control} render={() => null} defaultValue={[]} />
            </AccordionDetails>
          </Accordion>
        </Grid>

        <Grid item xs={12}>
          <Typography>
            You are uploading <strong>{sessionFile.file.name}</strong> with this session
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <WundaButton variant="outlined" color="primary" onClick={onCancel} sx={{ mr: 2 }}>
            Cancel
          </WundaButton>
          <WundaButton type="submit" variant="contained" color="primary" disabled={loading}>
            Create Insights
          </WundaButton>
        </Grid>
      </Grid>
      <Box mt={2}>
        {successMessage && <Alert severity="success">{successMessage}</Alert>}
        {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
      </Box>
      <Modal
        open={loading}
        onClose={loading ? null : handleCancel}
        closeAfterTransition
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            width: 400,
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            borderRadius: '10px', // Add this line to make the corners rounded
          }}
        >
          <WundaProgress value={uploadProgress} label="Uploading your audio..." />
          {uploadProgress === 100 ? (
            <>
              <Iconify icon={'svg-spinners:blocks-wave'} color="#D4FF51" />
              <Typography variant="caption" gutterBottom>
                File uploaded, but please hang in there as we are preparing your audio for processing...
              </Typography>
            </>
          ) : (
            <Box mt={2}>
              <WundaButton variant="outlined" color="primary" onClick={handleCancel}>
                Cancel
              </WundaButton>
            </Box>
          )}
        </Box>
      </Modal>
    </form>
  );
};

SessionUploadForm.propTypes = {
  sessionFile: PropTypes.object,
  onCancel: PropTypes.func,
  onSuccess: PropTypes.func,
};

export default SessionUploadForm;
