import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Typography, Stack, Card } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { AuthContext } from '../providers/AuthProvider';
import ErrorMessage from '../components/alert/ErrorMessage';
import WundaButton from '../components/wundamental/WundaButton';
import GenericSnackbar from '../components/snackbar/GenericSnackbar';
import Iconify from '../components/iconify';
import { useTheme } from '@mui/material/styles';
import WorkspaceCreationCard from '../components/workspace/WorkspaceCreationCard';
import GenericLoader from '../components/loader/GenericLoader';

const SubscriptionReturnPage = () => {
  const theme = useTheme();
  const { currentUser, refreshBalance, refreshUser } = useContext(AuthContext);
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showWorkspaceCreation, setShowWorkspaceCreation] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
  const [hasInitialized, setHasInitialized] = useState(false);

  useEffect(() => {
    let isSubscribed = true;
    let timeoutId = null;

    const initializeData = async () => {
      if (!isSubscribed || hasInitialized) return;

      const MAX_RETRIES = 3;
      const DELAY_MS = 3000;

      try {
        for (let attempt = 0; attempt < MAX_RETRIES; attempt++) {
          const updatedUser = await refreshUser();
          await refreshBalance();

          if (updatedUser?.DBuser?.subscription?.isActive) {
            if (isSubscribed) {
              setHasInitialized(true);
              setLoading(false);
            }
            break;
          }

          if (attempt < MAX_RETRIES - 1 && isSubscribed) {
            await new Promise((resolve) => {
              timeoutId = setTimeout(resolve, DELAY_MS);
            });
          }
        }

        if (isSubscribed && !updatedUser?.DBuser?.subscription?.isActive) {
          setErrorMessage(
            'Subscription activation is taking longer than expected. Please refresh the page or contact support.'
          );
        }
      } catch (error) {
        if (isSubscribed) {
          console.error('Error during subscription activation:', error);
          setErrorMessage('Error refreshing user data');
        }
      } finally {
        if (isSubscribed) {
          setLoading(false);
        }
      }
    };

    if (!hasInitialized) {
      initializeData();
    }

    return () => {
      isSubscribed = false;
      clearTimeout(timeoutId);
    };
  }, [hasInitialized, refreshUser, refreshBalance]);

  const showSuccess = (message) => {
    setSnackbar({ open: true, message, severity: 'success' });
  };

  const handleSnackbarClose = () => {
    setSnackbar({ open: false, message: '', severity: 'info' });
  };

  if (loading) {
    return (
      <Container
        maxWidth={false}
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '60vh' }}
      >
        <GenericLoader />
      </Container>
    );
  }

  const isTeamSubscription = currentUser?.DBuser?.subscription?.isTeamSubscription;
  const hasWorkspace = Boolean(currentUser?.DBuser?.workspace);

  return (
    <Container maxWidth={false}>
      <Helmet>
        <title> Subscription Complete | Wundamental </title>
      </Helmet>

      <Stack alignItems="center" mb={2}>
        {errorMessage ? (
          <ErrorMessage message={errorMessage} setMessage={setErrorMessage} />
        ) : (
          <Typography variant="h4" gutterBottom align="center">
            {'Subscription Successful!'}
          </Typography>
        )}
      </Stack>

      {!showWorkspaceCreation && (
        <Card
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '2em',
            boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)',
            maxWidth: '500px',
            margin: '1em auto',
          }}
        >
          <Iconify icon="material-symbols:check-circle-outline-rounded" width={50} height={50} />
          <Typography variant="h5" gutterBottom>
            You're All Set!
          </Typography>
          <Typography variant="body1" gutterBottom align="center">
            Your{' '}
            <span style={{ color: theme.palette.primary.main, fontWeight: 'bold' }}>
              {currentUser?.DBuser?.subscription?.plan}
            </span>{' '}
            subscription has been activated successfully. A confirmation email will be sent to your email address.
          </Typography>

          <Stack direction="column" spacing={2} mt={2} alignItems="center">
            {isTeamSubscription && !hasWorkspace && (
              <WundaButton
                variant="contained"
                color="primary"
                onClick={() => setShowWorkspaceCreation(true)}
                startIcon={<Iconify icon="material-symbols:add-business" />}
              >
                Create Team Workspace
              </WundaButton>
            )}
            <WundaButton
              variant="outlined"
              color={isTeamSubscription && !hasWorkspace ? 'inherit' : 'primary'}
              onClick={() => navigate('/home/sessions')}
            >
              Continue to Sessions
            </WundaButton>
          </Stack>
        </Card>
      )}

      {showWorkspaceCreation && (
        <WorkspaceCreationCard
          onSuccess={() => {
            showSuccess('Workspace created successfully!');
            navigate('/home/workspace');
          }}
          onCancel={() => setShowWorkspaceCreation(false)}
        />
      )}

      <Typography variant="body2" align="center" sx={{ mt: 3 }}>
        We are here for you, might you have questions:{' '}
        <a href="mailto:support@wundamental.ai">support@wundamental.ai</a>.
      </Typography>

      <GenericSnackbar open={snackbar} autoHideDuration={6000} onClose={handleSnackbarClose} />
    </Container>
  );
};

export default SubscriptionReturnPage;
