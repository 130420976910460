import React, { useContext, useState, useEffect, useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { logEvent } from 'firebase/analytics';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Popover,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  Modal,
  Box,
  Chip,
  Tooltip,
} from '@mui/material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
// components
import { FileWithMetadata } from '../utils/customTypes';
import GenericSnackbar from '../components/snackbar/GenericSnackbar';
import PromptDialog from '../components/dialog/PromptDialog';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
import GenericLoader from '../components/loader/GenericLoader';
import ErrorMessage from '../components/alert/ErrorMessage';
import SessionUploadForm from '../sections/@dashboard/session/SessionUploadForm';
import SessionEditForm from '../sections/@dashboard/session/SessionEditForm';
import StatusProgressLabel from '../components/wundamental/StatusProgressLabel';
import FileUploadStyled from '../components/file-upload/FileUploadStyled';
import FAQAccordion from '../components/faq/FAQAccordion';
import WundaButton from '../components/wundamental/WundaButton';
import WundaIconButton from '../components/wundamental/WundaIconButton';
import { analytics } from '../firebase/firebase';
import { AuthContext } from '../providers/AuthProvider';
// sections
import { SessionListToolbar, SessionListHead } from '../sections/@dashboard/session';
import { apiRequest } from '../api/api';
import languages from '../config/languages.json';
import WorkspaceInviteNotification from '../components/workspace/WorkspaceInviteNotification';
import WorkspaceCreationCard from '../components/workspace/WorkspaceCreationCard';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'open', label: '', hideOnMobile: true, maxWidth: '110px' },
  { id: 'name', label: 'Name' },
  { id: 'status', label: 'Status', align: 'center', maxWidth: '180px' },
  { id: 'uploadedAt', label: 'Date', hideOnMobile: true },
  { id: 'tags', label: 'Tags', hideOnMobile: true },
];
const FILTER_ENABLED = true;

// ----------------------------------------------------------------------

const ACCEPTED_FILE_TYPES = {
  'audio/aac': ['.aac'],
  'audio/flac': ['.flac'],
  'audio/x-m4a': ['.m4a'],
  'audio/mpeg': ['.mp3', '.mpeg'],
  'audio/mp4': ['.mp4'],
  'audio/ogg': ['.ogg'],
  'audio/wav': ['.wav'],
  'video/webm': ['.webm'],
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
  'text/plain': ['.txt'],
};

const FAQS = [
  {
    question: 'What will happen if I upload the file here and press Start?',
    answer:
      "Wundamental assumes your audio is a growth conversation between two persons - uploading any other audio will bring unexpected results. After uploading you are guided to provide your session details. After that the session audio will be analyzed to generate insights. You'll be able to see here the status of the session analysis process. Once ready, you will be able to see and manage the insights generated. We will also send a copy of the insights to your e-mail.",
  },
  {
    question: 'What languages do you support?',
    answer: `Sessions analyzed can be in any of the following languages: ${languages.map((lang) => lang.label).join(', ')}. The insights generated will be in English language. More languages coming soon.`,
  },
  {
    question: 'What audio files are supported?',
    answer:
      'We support AAC, FLAC, M4A, MP3, MP4, MPEG, OGG, WEBM, and WAV. If your file format is not in this list yet, write to us at hello@wundamental.ai, and we will do our best to include it.',
  },
  {
    question: 'Can I upload meeting recording as video file?',
    answer:
      'Yes, you can upload a video file with audio (MP4 file). We will extract the audio from the video and analyze it for insights.',
  },
  {
    question: 'How long does it take to analyze a session for insights?',
    answer:
      'It depends on the duration of the session’s audio uploaded. It may take 1 to 10 minutes to analyze for insights.',
  },
  {
    question: 'How can I record my online meeting to get the audio file?',
    answer:
      'You can record your online meeting using the built-in recording feature of the meeting platform. Once the meeting is over, you can download the recording and upload it here. See instructions for each platform: <a href="/home/how-to-record">How to record meetings?</a>.',
  },
  {
    question: 'How can I record a physical conversation to get the audio file?',
    answer:
      'You can use any voice recording app on your phone to record your conversation. There are many that are free like <a target="_new" href="https://apps.apple.com/us/app/voice-memos/id1069512134">Apple Voice Memos</a> for iOS, or <a target="_new" href="https://play.google.com/store/apps/details?id=com.andrwq.recorder&hl=en&gl=US&pli=1">Smart Voice Recorder</a> for Android. Once the conversation is over, you can upload the recording here.',
  },
  {
    question: 'How do you secure the privacy of my data?',
    answer:
      'We take security very seriously. We use industry-standard end-to-end encryption (E2EE) and data protection measures to ensure the privacy and security of all data. Your data is encrypted in transit as well as at rest (in database). You have full control over your data and you can delete any of your data from the Wundamental platform & servers at any time. Read more from our Privacy Policy.',
  },
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (item) =>
        item.name.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        (item.tags && item.tags.some((tag) => tag.toLowerCase().indexOf(query.toLowerCase()) !== -1))
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function SessionsPage() {
  const theme = useTheme();
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { currentUser, refreshBalance, setAdvancedBalanceMode, refreshUser } = useContext(AuthContext);
  const [hasUserRefreshed, setHasUserRefreshed] = useState(false);
  const snackbarRef = useRef(null);
  const [popoverOpen, setPopoverOpen] = useState(null);
  const [showSessionUploadModal, setShowSessionUploadModal] = useState(false);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('desc');

  const [orderBy, setOrderBy] = useState('uploadedAt');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [sessions, setSessions] = useState([]);
  const [deleteItemId, setDeleteItemId] = useState(null);
  const [retryItemId, setRetryItemId] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);

  const [loading, setLoading] = useState(true);

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const [openRetryDialog, setOpenRetryDialog] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [currentRow, setCurrentRow] = useState(null);
  const [inviteError, setInviteError] = useState(null);

  const [showWorkspaceCreation, setShowWorkspaceCreation] = useState(false);

  // Make sure we have latest user data
  if (!hasUserRefreshed) {
    // console.log('refreshing user data...');
    refreshUser();
    setHasUserRefreshed(true);
  }

  useEffect(() => {
    setAdvancedBalanceMode(false);
  }, [setAdvancedBalanceMode]);

  const handleStatusChange = useCallback((newStatus, sessionId) => {
    setSessions((sessions) =>
      sessions.map((session) => (session._id === sessionId ? { ...session, processingStatus: newStatus } : session))
    );
  }, []);

  const handleFileAccepted = (file) => {
    if (file.type.startsWith('audio/') || file.type.startsWith('video/')) {
      // You can open the upload form modal or directly upload the file as per your requirements
      setUploadedFile(file);
      setShowSessionUploadModal(true);
    } else if (
      file.type.startsWith('text/') ||
      file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
    ) {
      setUploadedFile(null);
      setErrorMessage('Uploading transcripts is not yet supported! Please upload an audio file!');
    } else {
      setUploadedFile(null);
      setErrorMessage('Unsupported file type! Please upload audio file!');
    }
  };

  const handleSessionUploadModalClose = () => {
    setShowSessionUploadModal(false);
    setUploadedFile(null);
  };

  const handleSessionUploadSuccess = () => {
    // Refresh the table data
    setUploadedFile(null);
    showSuccess('Successfully uploaded session. Analysis for insights started.');
    fetchSessions();
  };

  const handleProgressReady = () => {
    fetchSessions();
    refreshBalance();
  };

  const handleOpenSession = async (row) => {
    navigate(`/home/sessions/${row._id}`);
  };

  const handleOpenMenu = (itemId, event) => {
    event.preventDefault();
    const selectedRow = sessions.find((row) => row._id === itemId);
    setCurrentRow(selectedRow);
    setDeleteItemId(itemId);
    setRetryItemId(itemId);
    setPopoverOpen(event.currentTarget);
  };

  const handleOpenEdit = (row) => {
    setSelectedRow(row);
    setShowEditModal(true);
  };

  const handleCloseEdit = () => {
    setShowEditModal(false);
    handleCloseMenu();
  };

  const handleEditSessionSuccess = () => {
    showSuccess('Successfully updated the session!');
    fetchSessions();
  };

  const handleCancelConfirm = () => {
    setOpenCancelDialog(true);
  };

  const handleCancelCancel = () => {
    handleCloseMenu();
    setOpenCancelDialog(false);
  };

  const handleCloseMenu = () => {
    setPopoverOpen(null);
    setCurrentRow(null); // Clear the currently selected row
    setDeleteItemId(null); // Clear the delete item id
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const showSuccess = (message) => {
    snackbarRef.current.showSnackbar({ message, severity: 'success' });
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const handleDeleteConfirm = () => {
    setOpenDeleteDialog(true);
  };

  const handleDeleteCancel = () => {
    handleCloseMenu();
    setOpenDeleteDialog(false);
  };

  const handleDeleteConfirmDialog = async () => {
    handleCloseMenu();
    setOpenDeleteDialog(false);
    setOpenCancelDialog(false);
    setLoading(true);
    try {
      await apiRequest('DELETE', `session/${deleteItemId}`);
      logEvent(analytics, 'delete_session', {
        sessionId: deleteItemId,
        method: 'sessions_page',
      });
      fetchSessions();
      showSuccess('Successfully deleted a session!');
    } catch (error) {
      console.error(`Error deleting session ${deleteItemId}`, error);
      setErrorMessage(`Error deleting a session! ${error.response?.data?.message || error.message}`);
    } finally {
      setLoading(false);
    }
  };

  const handleRetryConfirm = () => {
    setOpenRetryDialog(true);
  };

  const handleRetryCancel = () => {
    handleCloseMenu();
    setOpenRetryDialog(false);
  };

  const handleRetryConfirmDialog = async () => {
    setOpenRetryDialog(false);
    handleCloseMenu();
    setLoading(true);
    try {
      await apiRequest('POST', `session/retry/${retryItemId}`);
      showSuccess('Successfully triggered retry of a session processing!');
      logEvent(analytics, 'retry_session', {
        sessionId: retryItemId,
        method: 'sessions_page',
      });
      fetchSessions();
    } catch (error) {
      console.error(`Error retrying session ${retryItemId}`, error);
      setErrorMessage(`Error retrying a session! ${error.response?.data?.message || error.message}`);
    } finally {
      setLoading(false);
    }
  };

  const fetchSessions = useCallback(async () => {
    setLoading(true);

    try {
      const response = await apiRequest('GET', `session/list`);
      refreshBalance();
      setSessions(response);
    } catch (error) {
      console.error('Error fetching sessions:', error.message);
      setErrorMessage(`Error fetching sessions! ${error.response?.data?.message || error.message}`);
    } finally {
      setLoading(false);
    }
  }, [setSessions, setLoading, refreshBalance]);

  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: isMobile ? '100%' : '50%', // If screen size is small, width is 100%, else it's 50%.
    bgcolor: 'background.paper',
    border: '2px solid #365a47',
    boxShadow: 24,
    borderRadius: '10px',
    p: 4,
  };

  useEffect(() => {
    fetchSessions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - sessions.length) : 0;

  const filteredSessions = applySortFilter(sessions, getComparator(order, orderBy), filterName);

  const isNotFound = !filteredSessions.length && !!filterName;

  const handleAcceptInvite = async () => {
    try {
      await apiRequest('POST', '/workspace/accept-invite', {
        data: {
          workspaceId: currentUser.DBuser.workspaceInvitation.workspaceId,
        },
      });
      showSuccess('Successfully joined the workspace!');
      refreshUser();
    } catch (error) {
      setInviteError(`Error accepting invitation: ${error.message}`);
    }
  };

  const handleDeclineInvite = async () => {
    try {
      await apiRequest('POST', '/workspace/decline-invite', {
        data: {
          workspaceId: currentUser.DBuser.workspaceInvitation.workspaceId,
        },
      });
      showSuccess('Invitation declined');
      refreshUser();
    } catch (error) {
      setInviteError(`Error declining invitation: ${error.message}`);
    }
  };

  return (
    <>
      <Helmet>
        <title> Sessions | Wundamental </title>
      </Helmet>

      <Container maxWidth={false} sx={{ mt: 2 }}>
        {loading && <GenericLoader />}
        <Card>
          <ErrorMessage message={errorMessage} setMessage={setErrorMessage} />
          <ErrorMessage message={inviteError} setMessage={setInviteError} />

          {currentUser?.DBuser?.workspaceInvitation && (
            <WorkspaceInviteNotification
              workspaceName={currentUser.DBuser.workspaceInvitation.workspaceName}
              onAccept={handleAcceptInvite}
              onDecline={handleDeclineInvite}
            />
          )}

          {currentUser?.DBuser?.subscription?.isTeamSubscription &&
            currentUser?.DBuser?.subscription?.isActive &&
            !currentUser?.DBuser?.workspace &&
            !showWorkspaceCreation && (
              <Card
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  padding: '2em',
                  boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)',
                  maxWidth: '500px',
                  margin: '1em auto',
                }}
              >
                <Iconify icon="material-symbols:add-business" width={50} height={50} />
                <Typography variant="h5" gutterBottom>
                  Create Your Workspace
                </Typography>
                <Typography variant="body1" gutterBottom align="center">
                  You have an active team subscription, so you can now create your team workspace to start collaborating
                  with your team.
                </Typography>
                <WundaButton variant="contained" color="primary" onClick={() => setShowWorkspaceCreation(true)}>
                  Create Workspace
                </WundaButton>
              </Card>
            )}

          {showWorkspaceCreation && (
            <WorkspaceCreationCard
              onSuccess={() => {
                showSuccess('Workspace created successfully!');
                navigate('/home/workspace');
              }}
              onCancel={() => setShowWorkspaceCreation(false)}
            />
          )}

          {currentUser.DBuser &&
          currentUser.DBuser.balance?.sessionCredits < 1 &&
          currentUser.DBuser.balance?.subscriptionCredits < 1 &&
          !currentUser.DBuser.balance?.hasInfiniteBalance ? (
            <Card
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '2em',
                boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)',
                maxWidth: '500px',
                margin: '1em auto', // Add top and bottom margin
              }}
            >
              <Iconify icon={'material-symbols:sentiment-dissatisfied-rounded'} width={50} height={50} />
              <Typography variant="h5" gutterBottom>
                Oops! You've run out of sessions.
              </Typography>
              <Typography variant="body1" gutterBottom>
                Don't worry, you can buy more now.
              </Typography>
              {currentUser.DBuser && currentUser.DBuser.canBuyCredits ? (
                <WundaButton
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    logEvent(analytics, 'buy_click', { method: 'sessions_page' });
                    navigate('/home/subscribe');
                  }}
                >
                  Buy More Credits
                </WundaButton>
              ) : (
                <Typography variant="body2" color="text.primary">
                  Reach out to your workspace owner for more
                </Typography>
              )}
            </Card>
          ) : (
            <FileUploadStyled
              onFileAccepted={handleFileAccepted}
              acceptedTypes={ACCEPTED_FILE_TYPES}
              labelText={'Drop session audio or video file here or click “Start” button'}
              uploadedFile={uploadedFile}
              setUploadedFile={setUploadedFile}
            />
          )}

          {sessions.length > 0 && (
            <>
              <Stack direction="row" alignItems="center" justifyContent="space-between" mt={3}>
                <Typography variant="h4" sx={{ paddingLeft: theme.spacing(3) }}>
                  Your sessions
                </Typography>
              </Stack>
              <SessionListToolbar
                filterName={filterName}
                onFilterName={handleFilterByName}
                isEnabled={FILTER_ENABLED}
              />
              <Scrollbar>
                <TableContainer component={Paper} sx={{ margin: 'auto' }}>
                  <Table sx={{ tableLayout: 'fixed' }}>
                    <SessionListHead
                      order={order}
                      orderBy={orderBy}
                      headLabel={TABLE_HEAD}
                      rowCount={sessions.length}
                      onRequestSort={handleRequestSort}
                    />
                    <TableBody>
                      {filteredSessions.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                        const { _id, name, processingStatus, sessionDate, tags, lastError } = row;

                        return (
                          <TableRow hover key={_id} tabIndex={-1} role="checkbox">
                            {!isMobile && (
                              <TableCell
                                align="right"
                                padding="none"
                                sx={{
                                  position: 'sticky',
                                  right: 0,
                                  backgroundColor: theme.palette.background.paper,
                                  zIndex: 2,
                                }}
                              >
                                <IconButton
                                  size="large"
                                  color="inherit"
                                  onClick={(event) => handleOpenMenu(_id, event)}
                                >
                                  <Iconify icon={'eva:more-vertical-fill'} />
                                </IconButton>
                              </TableCell>
                            )}

                            <TableCell align="left" style={{ overflow: 'hidden', padding: '0 5px' }}>
                              {processingStatus === 'READY' &&
                                (isMobile ? (
                                  <WundaIconButton
                                    icon="eva:external-link-outline" // replace with the correct icon name
                                    tooltip="Open"
                                    onClick={() => {
                                      handleOpenSession(row);
                                    }}
                                  />
                                ) : (
                                  <WundaButton
                                    variant="outlined"
                                    size="small"
                                    title="Open"
                                    startIcon={<OpenInNewIcon />}
                                    style={{
                                      marginRight: '10px',
                                      minWidth: 'auto',
                                      padding: '6px 16px',
                                    }}
                                    onClick={() => {
                                      handleOpenSession(row);
                                    }}
                                  >
                                    Open
                                  </WundaButton>
                                ))}
                              {processingStatus === 'FAILED' &&
                                (isMobile ? (
                                  <WundaIconButton
                                    icon="eva:refresh-outline" // replace with the correct icon name
                                    tooltip="Retry"
                                    onClick={() => {
                                      setRetryItemId(_id);
                                      handleRetryConfirm();
                                    }}
                                  />
                                ) : (
                                  <WundaButton
                                    variant="outlined"
                                    size="small"
                                    title="Retry"
                                    startIcon={<Iconify icon={'eva:refresh-outline'} />}
                                    style={{
                                      marginRight: '10px',
                                      minWidth: 'auto',
                                      padding: '6px 10px',
                                    }}
                                    onClick={() => {
                                      setRetryItemId(_id);
                                      handleRetryConfirm();
                                    }}
                                  >
                                    Retry
                                  </WundaButton>
                                ))}
                              {processingStatus !== 'READY' &&
                                processingStatus !== 'FAILED' &&
                                (isMobile ? (
                                  <WundaIconButton
                                    icon="eva:close-outline" // replace with the correct icon name
                                    tooltip="Cancel"
                                    onClick={() => {
                                      setDeleteItemId(_id);
                                      handleCancelConfirm();
                                    }}
                                  />
                                ) : (
                                  <WundaButton
                                    variant="outlined"
                                    size="small"
                                    title="Cancel"
                                    startIcon={<Iconify icon={'eva:close-outline'} />}
                                    style={{
                                      marginRight: '10px',
                                      minWidth: 'auto',
                                      padding: '6px 10px',
                                    }}
                                    onClick={() => {
                                      setDeleteItemId(_id);
                                      handleCancelConfirm();
                                    }}
                                  >
                                    Cancel
                                  </WundaButton>
                                ))}
                            </TableCell>

                            <TableCell
                              component="th"
                              scope="row"
                              padding="none"
                              sx={{
                                cursor: isMobile && processingStatus === 'READY' ? 'pointer' : 'default',
                                whiteSpace: 'normal',
                              }}
                              onClick={() => {
                                if (isMobile && processingStatus === 'READY') {
                                  handleOpenSession(row);
                                }
                              }}
                            >
                              <Stack direction="row" alignItems="center" spacing={2}>
                                <Typography variant="subtitle2" className="fs-mask">
                                  {name}
                                </Typography>
                              </Stack>
                            </TableCell>
                            <TableCell align="center">
                              <StatusProgressLabel
                                key={`${_id}-${processingStatus}-{${lastError ? 'err' : 'ok'}}`}
                                status={processingStatus}
                                url={`session/status/${_id}`}
                                onStatusChange={(newStatus) => handleStatusChange(newStatus, _id)}
                                onReady={() => handleProgressReady()}
                                pollingInterval={30}
                                showError={processingStatus === 'FAILED' && lastError ? lastError : null}
                              />
                            </TableCell>
                            {!isMobile && (
                              <>
                                <TableCell align="left">
                                  {sessionDate
                                    ? new Date(sessionDate).toLocaleDateString('en-US', {
                                        year: 'numeric',
                                        month: 'long',
                                        day: 'numeric',
                                        hour: '2-digit',
                                        minute: '2-digit',
                                        hour12: false,
                                      })
                                    : ''}
                                </TableCell>
                                <TableCell sx={{ whiteSpace: 'normal' }}>
                                  <Box sx={{ maxWidth: '150px' }}>
                                    {tags && tags.map((tag, index) => <Chip key={index} label={tag} />)}
                                  </Box>
                                  <Tooltip title={tags && tags.join(', ')}>
                                    <Box sx={{ maxWidth: '150px' }}>{/* tags */}</Box>
                                  </Tooltip>
                                </TableCell>
                              </>
                            )}
                          </TableRow>
                        );
                      })}
                      {emptyRows > 0 && (
                        <TableRow style={{ height: 53 * emptyRows }}>
                          <TableCell colSpan={6} />
                        </TableRow>
                      )}
                    </TableBody>

                    {isNotFound && (
                      <TableBody>
                        <TableRow>
                          <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                            <Paper
                              sx={{
                                textAlign: 'center',
                              }}
                            >
                              <Typography variant="h6" paragraph>
                                Not found
                              </Typography>

                              <Typography variant="body2" className="fs-mask">
                                No results found for &nbsp;
                                <strong>&quot;{filterName}&quot;</strong>.
                              </Typography>
                            </Paper>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                  </Table>
                </TableContainer>
              </Scrollbar>

              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={sessions.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </>
          )}
        </Card>

        <Card sx={{ marginTop: 2 }}>
          <FAQAccordion faqs={FAQS} sx={{ width: '100%' }} />
        </Card>
      </Container>

      <PromptDialog
        open={openDeleteDialog}
        title="Confirm Deletion"
        message="Are you sure you want to delete this item?"
        cancelButtonLabel="No"
        confirmButtonLabel="Yes"
        onCancel={handleDeleteCancel}
        onConfirm={handleDeleteConfirmDialog}
      />

      <PromptDialog
        open={openCancelDialog}
        title="Confirm Cancellation"
        message="Are you sure you want to cancel this session file upload?"
        cancelButtonLabel="Never mind"
        confirmButtonLabel="Yes"
        onCancel={handleCancelCancel}
        onConfirm={handleDeleteConfirmDialog}
      />

      <PromptDialog
        open={openRetryDialog}
        title="Confirm Retry"
        message="Are you sure you want to retry this session?"
        cancelButtonLabel="Never mind"
        confirmButtonLabel="Yes"
        onCancel={handleRetryCancel}
        onConfirm={handleRetryConfirmDialog}
      />

      <Modal open={showEditModal} onClose={handleCloseEdit}>
        <Box sx={modalStyle}>
          {selectedRow && (
            <SessionEditForm
              sessionId={selectedRow._id}
              onCancel={handleCloseEdit}
              onSuccess={handleEditSessionSuccess}
            />
          )}
        </Box>
      </Modal>

      <Modal open={showSessionUploadModal} onClose={() => setShowSessionUploadModal(false)}>
        <Box sx={modalStyle}>
          <SessionUploadForm
            sessionFile={FileWithMetadata(uploadedFile)}
            onSuccess={handleSessionUploadSuccess}
            onCancel={handleSessionUploadModalClose}
          />
        </Box>
      </Modal>

      <Popover
        open={Boolean(popoverOpen)}
        anchorEl={popoverOpen}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        {currentRow && currentRow.processingStatus === 'FAILED' && (
          <MenuItem onClick={handleRetryConfirm}>
            <Iconify icon={'eva:refresh-outline'} sx={{ mr: 2 }} />
            Retry
          </MenuItem>
        )}

        {currentRow && currentRow.processingStatus !== 'READY' && currentRow.processingStatus !== 'FAILED' && (
          <MenuItem onClick={handleCancelConfirm}>
            <Iconify icon={'eva:close-outline'} sx={{ mr: 2 }} />
            Cancel
          </MenuItem>
        )}

        {currentRow && currentRow.processingStatus === 'READY' && (
          <MenuItem onClick={() => handleOpenSession(currentRow)}>
            <Iconify icon={'ion:open-outline'} sx={{ mr: 2 }} />
            Open
          </MenuItem>
        )}

        {currentRow && (
          <MenuItem
            onClick={currentRow.demoForAll ? null : () => handleOpenEdit(currentRow)}
            disabled={currentRow.demoForAll}
          >
            <Iconify icon={'eva:edit-2-outline'} sx={{ mr: 2 }} />
            Edit
          </MenuItem>
        )}

        {currentRow && (currentRow.processingStatus === 'READY' || currentRow.processingStatus === 'FAILED') && (
          <MenuItem sx={{ color: 'error.main' }} onClick={handleDeleteConfirm}>
            <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
            Delete
          </MenuItem>
        )}
      </Popover>

      <GenericSnackbar ref={snackbarRef} />
    </>
  );
}
