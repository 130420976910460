import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { styled, useTheme } from '@mui/material/styles';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, TextField } from '@mui/material';
import WundaButton from '../wundamental/WundaButton';

const PromptDialog = ({
  open,
  title,
  message,
  onCancel,
  onConfirm,
  confirmWithName,
  cancelButtonLabel,
  confirmButtonLabel,
}) => {
  const [name, setName] = useState('');
  const theme = useTheme();

  const StyledDialogTitle = styled(DialogTitle)({
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    fontWeight: 'bold',
    padding: '20px',
  });

  const StyledDialogContent = styled(DialogContent)({
    color: theme.palette.text.primary,
    padding: '20px',
    marginTop: '20px',
  });

  const handleClose = () => {
    if (onCancel) {
      onCancel();
    }
  };

  const handleConfirm = () => {
    if (onConfirm) {
      onConfirm();
    }
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <StyledDialogTitle>{title}</StyledDialogTitle>
      <StyledDialogContent>
        <DialogContentText>{message}</DialogContentText>
        {confirmWithName && (
          <TextField
            autoFocus
            margin="dense"
            label={`Confirm by typing your name (${confirmWithName}) below`}
            type="text"
            fullWidth
            value={name}
            onChange={(event) => setName(event.target.value)}
            sx={{ marginTop: 2 }}
          />
        )}
      </StyledDialogContent>
      <DialogActions>
        <WundaButton onClick={handleClose} color="primary" variant="outlined" autoFocus>
          {cancelButtonLabel || 'Cancel'}
        </WundaButton>
        <WundaButton
          onClick={handleConfirm}
          color="error"
          variant="contained"
          disabled={confirmWithName && name !== confirmWithName}
        >
          {confirmButtonLabel || 'Confirm'}
        </WundaButton>
      </DialogActions>
    </Dialog>
  );
};

PromptDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
  confirmWithName: PropTypes.string,
  cancelButtonLabel: PropTypes.string,
  confirmButtonLabel: PropTypes.string,
};

export default PromptDialog;
