import React, { useState, useContext } from 'react';
import { Card, Typography, Box, TextField, Stack } from '@mui/material';
import PropTypes from 'prop-types';
import { AuthContext } from '../../providers/AuthProvider';
import { apiRequest } from '../../api/api';
import Iconify from '../iconify';
import WundaButton from '../wundamental/WundaButton';

const WorkspaceCreationCard = ({ onSuccess, onCancel }) => {
  const { refreshUser } = useContext(AuthContext);
  const [workspaceName, setWorkspaceName] = useState('');
  const [isCreatingWorkspace, setIsCreatingWorkspace] = useState(false);
  const [error, setError] = useState(null);

  const handleCreateWorkspace = async () => {
    if (!workspaceName.trim()) {
      setError('Please enter a workspace name');
      return;
    }

    setIsCreatingWorkspace(true);
    try {
      await apiRequest('POST', '/workspace', {
        data: { name: workspaceName.trim() },
      });
      await refreshUser();
      if (onSuccess) {
        onSuccess();
      }
    } catch (error) {
      setError(`Error creating workspace: ${error.message}`);
    } finally {
      setIsCreatingWorkspace(false);
    }
  };

  return (
    <Card
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '2em',
        boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)',
        maxWidth: '500px',
        margin: '1em auto',
      }}
    >
      <Iconify icon="material-symbols:add-business" width={50} height={50} />
      <Typography variant="h5" gutterBottom>
        Create Your Workspace
      </Typography>
      <Typography variant="body1" gutterBottom align="center">
        Create your team workspace to start collaborating with your team.
      </Typography>

      <Box sx={{ width: '100%', mt: 2, mb: 3 }}>
        <TextField
          fullWidth
          label="Workspace Name"
          value={workspaceName}
          onChange={(e) => setWorkspaceName(e.target.value)}
          disabled={isCreatingWorkspace}
          error={!!error}
          helperText={error}
        />
      </Box>

      <Stack direction="row" spacing={2} mt={2}>
        <WundaButton variant="outlined" color="inherit" onClick={onCancel}>
          Cancel
        </WundaButton>
        <WundaButton variant="contained" color="primary" onClick={handleCreateWorkspace} disabled={isCreatingWorkspace}>
          Create Workspace
        </WundaButton>
      </Stack>
    </Card>
  );
};

WorkspaceCreationCard.propTypes = {
  onSuccess: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
};

export default WorkspaceCreationCard;
