import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { Controller, useForm } from 'react-hook-form';
import {
  TextField,
  Grid,
  Autocomplete,
  Typography,
  Box,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
} from '@mui/material';
import { countries, getCountryCode } from 'countries-list';
import { AuthContext } from '../../../providers/AuthProvider';
import ErrorMessage from '../../../components/alert/ErrorMessage';
import WundaButton from '../../../components/wundamental/WundaButton';
import { apiRequest } from '../../../api/api';

const BillingInformationForm = ({ onSuccess, onBack, showBackButton, submitButtonText, formTitle }) => {
  const { currentUser, refreshUser } = useContext(AuthContext);
  const isTestMode = process.env.REACT_APP_STRIPE_LIVE_MODE_ENABLED !== 'true';
  const initialCountryCode =
    currentUser.DBuser.billingInformation && currentUser.DBuser.billingInformation.country
      ? currentUser.DBuser.billingInformation.country
      : null;
  const allCountries = [
    { name: 'Select a country', code: null },
    ...Object.values(countries)
      .map((country) => ({
        code: getCountryCode(country.name),
        name: country.name,
      }))
      .sort((a, b) => a.name.localeCompare(b.name)), // Sort countries alphabetically
  ];
  // Convert stored country code to matching object for the Autocomplete
  const initialCountryObject = allCountries.find((c) => c.code === initialCountryCode) || null;

  const [billingType, setBillingType] = useState(
    currentUser.DBuser.billingInformation && currentUser.DBuser.billingInformation.billingType
      ? currentUser.DBuser.billingInformation.billingType
      : 'personal'
  );

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: currentUser.DBuser?.name || '',
      companyName: currentUser.DBuser?.billingInformation?.companyName || '',
      vat: currentUser.DBuser?.billingInformation?.vat || '',
      address: currentUser.DBuser?.billingInformation?.address || '',
      city: currentUser.DBuser?.billingInformation?.city || '',
      state: currentUser.DBuser?.billingInformation?.state || '',
      country: initialCountryObject,
      postalCode: currentUser.DBuser?.billingInformation?.postalCode || '',
      billingType,
      ...currentUser.DBuser?.billingInformation,
    },
  });

  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    setValue('billingType', billingType);
  }, [billingType, setValue]);

  const onSubmit = async (data) => {
    const { billingType, name, companyName, vat, address, city, state, country, postalCode } = data;
    const payload = {
      // Other user fields if needed
      name,
      billingInformation: {
        billingType,
        companyName,
        vat,
        address,
        city,
        state,
        country,
        postalCode,
      },
      isTestMode,
    };

    try {
      // We're using 'PUT' here because it's an update operation
      await apiRequest('PUT', `/payment/customer/${currentUser.DBuser._id}`, {
        data: JSON.stringify(payload),
        headers: {
          'Content-Type': 'application/json',
        },
      });

      refreshUser(); // Refresh the user data to reflect the changes

      if (onSuccess) {
        onSuccess('Successfully updated the billing information');
      }
    } catch (error) {
      console.error('Failed to update the billing information:', error);
      setErrorMessage(`Error on checkout! ${error.response?.data?.errorDetail || error.message}`);
    }
  };

  // Generic VAT validation rule
  const vatValidation = {
    pattern: {
      value: /^[A-Z]{0,2}[0-9A-Z]{8,14}$/, // Basic pattern for VAT number
      message: 'Invalid VAT number format',
    },
  };

  return (
    <>
      {formTitle && (
        <Box sx={{ marginBottom: 2 }}>
          <Typography component="h1" variant="h5">
            {formTitle}
          </Typography>
        </Box>
      )}
      <ErrorMessage message={errorMessage} setMessage={setErrorMessage} />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControl component="fieldset">
              <RadioGroup
                row
                name="billingType"
                value={billingType}
                onChange={(event) => setBillingType(event.target.value)}
              >
                <FormControlLabel value="personal" control={<Radio />} label="Personal" />
                <FormControlLabel value="business" control={<Radio />} label="Business" />
              </RadioGroup>
            </FormControl>
          </Grid>

          {billingType === 'personal' && (
            <Grid item xs={12}>
              <Controller
                name="name"
                control={control}
                defaultValue=""
                rules={{ required: 'Name is required' }}
                className="fs-mask"
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Full name"
                    error={Boolean(errors.name)}
                    helperText={errors.name?.message}
                    fullWidth
                    value={field.value || ''}
                  />
                )}
              />
            </Grid>
          )}

          {billingType === 'business' && (
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Controller
                    name="companyName"
                    control={control}
                    defaultValue=""
                    className="fs-mask"
                    rules={{ required: billingType === 'business' ? 'Company Name is required' : false }} // Add this line
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="Company Name"
                        error={Boolean(errors.companyName)}
                        helperText={errors.companyName?.message}
                        fullWidth
                        value={field.value || ''}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controller
                    name="vat"
                    control={control}
                    defaultValue=""
                    className="fs-mask"
                    rules={vatValidation} // Apply validation rules to the VAT field
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="VAT"
                        error={Boolean(errors.vat)}
                        helperText={errors.vat?.message}
                        fullWidth
                        value={field.value || ''}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
          )}
          <Grid item xs={9}>
            <Controller
              name="address"
              control={control}
              defaultValue=""
              className="fs-mask"
              rules={{ required: 'Address is required' }}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Address"
                  error={Boolean(errors.address)}
                  helperText={errors.address?.message}
                  fullWidth
                  value={field.value || ''}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              name="city"
              control={control}
              defaultValue=""
              className="fs-mask"
              rules={{ required: 'City is required' }}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="City"
                  error={Boolean(errors.city)}
                  helperText={errors.city?.message}
                  fullWidth
                  value={field.value || ''}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Controller
              name="state"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  label="State"
                  error={Boolean(errors.state)}
                  helperText={errors.state?.message}
                  fullWidth
                  value={field.value || ''}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              control={control}
              name="country"
              rules={{
                required: 'Country is required',
                validate: (value) => (value && value.code !== null) || 'Country is required',
              }}
              render={({ field: { onChange, value, ref, ...field } }) => (
                <Autocomplete
                  {...field}
                  options={allCountries}
                  getOptionLabel={(option) => option?.name || ''}
                  isOptionEqualToValue={(option, value) => option?.code === value?.code}
                  onChange={(event, item) => onChange(item ? item.code : '')} // Store the country code as the value
                  value={allCountries.find((country) => country.code === value) || null} // Match the country object based on the code
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Country"
                      error={Boolean(errors.country)}
                      helperText={errors.country?.message}
                      fullWidth
                      inputRef={ref}
                    />
                  )}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={2}>
            <Controller
              name="postalCode"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Postal Code"
                  error={Boolean(errors.postalCode)}
                  helperText={errors.postalCode?.message}
                  fullWidth
                  value={field.value || ''}
                />
              )}
            />
          </Grid>
        </Grid>
        <Box mt={2}>
          <Grid container spacing={2} justifyContent="space-between">
            {showBackButton && (
              <Grid item>
                <WundaButton variant="contained" color="secondary" onClick={onBack}>
                  Back
                </WundaButton>
              </Grid>
            )}
            <Grid item>
              <WundaButton type="submit" variant="contained" color="primary">
                {submitButtonText}
              </WundaButton>
            </Grid>
          </Grid>
        </Box>
      </form>
    </>
  );
};

BillingInformationForm.propTypes = {
  onSuccess: PropTypes.func.isRequired,
  onBack: (props, propName, componentName) => {
    if (props.showBackButton === true && (props[propName] == null || typeof props[propName] !== 'function')) {
      return new Error(
        `Invalid prop \`${propName}\` supplied to \`${componentName}\`. Expected a function when prop \`showBackButton\` is true.`
      );
    }
    return null;
  },
  showBackButton: PropTypes.bool,
  submitButtonText: PropTypes.string,
  formTitle: PropTypes.string,
};

export default BillingInformationForm;
